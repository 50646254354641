<script>
import {Carousel, Slide} from "vue-carousel";
import {
  UserIcon,
  MailIcon,
  KeyIcon,
  ArrowUpIcon,
  HelpCircleIcon,
} from "vue-feather-icons";
import Navbar from "@/views/new-view/components/navbar";
import Switcher from "@/components/switcher";
import Footer from "@/components/footer";
import footerBar from "@/views/new-view/components/footerBar";
import homeApi from "@/apis/homeApi";
import indexApi from "@/apis/indexApi";
import planApi from "@/apis/planApi";
import storage from '../storage';
import Features from "@/components/features";
import plans from "@/views/new-view/components/index-plans";

import Testimonial from "@/components/testimonial";
/**
 * Index-1
 */
export default {
  data() {
    return {
      url: "",
      imageLoad: true,
      wallet: undefined,
      dashBoard: undefined,
      plans: undefined,
      plansLoad: 0,
      login: false,
      planForm: {
        pageNum: 1,
        pageSize: 12
      },
      featuresData: [
        {
          icon: "uil uil-rocket",
          title: "150.5 Ph/s",
          description:
              "In management",
        },
        {
          icon: "uil uil-rss",
          title: "99.97%",
          description:
              "Uptime",
        },
        {
          icon: "uil uil-bitcoin-circle",
          title: "0%",
          description:
              "No commissions for withdrawal",
        },
        {
          icon: "uil uil-head-side-cough",
          title: "24/7",
          description:
              "Support",
        },
        {
          icon: "uil uil-bolt",
          title: "",
          description:
              "Mining starts immediately after payment",
        },
        {
          icon: "uil uil-wallet",
          title: "",
          description:
              "No service fees",
        },
        {
          icon: "uil uil-bitcoin-alt",
          title: "",
          description:
              "Supports USDT, BTC, ETH, and LTC wallet address withdrawal",
        },
        {
          icon: "uil uil-processor",
          title: "",
          description:
              "No need to buy equipment",
        }
      ],
      testimonialData: [
        {
          id: 1,
          profile: "images/client/01.jpg",
          message:
              "It seems that only fragments of the original text remain in the Lorem Ipsum texts used today.",
          name: "Thomas Israel",
          designation: "C.E.O",
        },
        {
          id: 2,
          profile: "images/client/02.jpg",
          message:
              "One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others.",
          name: "Barbara McIntosh",
          designation: "M.D",
        },
        {
          id: 3,
          profile: "images/client/03.jpg",
          message:
              "The most well-known dummy text is the 'Lorem Ipsum', which is said to have originated in the 16th century.",
          name: "Carl Oliver",
          designation: "P.A",
        },
        {
          id: 4,
          profile: "images/client/04.jpg",
          message:
              "According to most sources, Lorum Ipsum can be traced back to a text composed by Cicero.",
          name: "Christa Smith",
          designation: "Manager",
        },
        {
          id: 5,
          profile: "images/client/05.jpg",
          message:
              "There is now an abundance of readable dummy texts. These are usually used when a text is required.",
          name: "Dean Tolle",
          designation: "Developer",
        },
        {
          id: 6,
          profile: "images/client/05.jpg",
          message:
              "Thus, Lorem Ipsum has only limited suitability as a visual filler for German texts.",
          name: "Jill Webb",
          designation: "Designer",
        },
      ],
      bottom: "",
      planType: 'plans'
    }
  },
  components: {
    Navbar,
    Switcher,
    Carousel,
    Slide,
    UserIcon,
    MailIcon,
    KeyIcon,
    ArrowUpIcon,
    Footer,
    HelpCircleIcon,
    plans,
    Features,
    Testimonial,
    // Navbar2,
    footerBar
  },
  mounted() {
    this.refreshPlans();
    setInterval(this.refreshPlans, 60000);
  },
  created() {
    //获取Banner图片
    // homeApi.banner(res => {
    //   this.url = res.data.data
    // })

    //if login
    let userId = storage.localGet("userId")
    if (userId) {
      this.login = true
      //获取仪表盘信息
      indexApi.dashBoard(res => {
        if (res.code != 200) {
          storage.localDel("userId")
          this.login = false
        } else {
          this.dashBoard = res.data
        }
      })
    }
    if (!this.login) {
      this.planForm.pageSize = 12
    }
  },
  methods: {
    refreshPlans(){
      planApi.list(this.planForm, res => {
        this.plans = res.data
      })
    },
    loadmore() {
      this.planForm.pageNum++
      //开始加载
      this.plansLoad = 1
      let pages = Math.ceil(this.plans.totalCount / this.planForm.pageSize)
      //获取项目列表
      if (pages >= this.planForm.pageNum) {
        planApi.list(this.planForm, res => {
          res.data.result.unshift(...this.plans.result)
          Object.assign(this.plans, res.data)
          this.plansLoad = 0
          console.log(pages)
          console.log(this.planForm.pageNum)
          if (pages <= this.planForm.pageNum) this.plansLoad = -1//结束加载
        })
      } else {
        this.plansLoad = -1;
      }
    }
  },
};
</script>

<template>
  <div>
    <Navbar />
    <!-- 顶部 -->
    <section class="bg-half bg-light d-table w-100">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 text-center">
            <div class="page-next-level">
              <h4 class="title" style="margin-top: -80px;">Choose Your Plans</h4><br/>
              <div class="page-next">
                <nav aria-label="breadcrumb" class="d-inline-block">
                  <ul class="breadcrumb bg-white rounded shadow mb-0">
                    <li class="breadcrumb-item">
                      <router-link to="/">Home</router-link>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Choose Your Plans
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!-- 遮蔽罩 -->
    <!-- Shape Start -->
    <div class="position-relative">
      <div class="shape overflow-hidden text-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
      </div>
    </div>
    <!--Shape End-->
    <!-- 登录与未登录通用 -->
    <section class="section pt-0">
<!--      background-color: rgba(247, 250, 255, 0.9)-->
      <div class="container" style="margin-top: 10px;padding-top: 10px">
<!--        <h4 v-if="!login" class="mt-20 col-12 text-center mb-4">Choose Your Plan</h4>-->
<!--        <h4 v-else >Investment Plans</h4>-->
        <el-row v-if="plans===undefined" v-loading="plans===undefined" element-loading-background="rgba(0, 0, 0, 0)">
        </el-row>
        <el-row v-else>
          <plans :data="plans.result" :type="planType"></plans>

        </el-row>
        <!-- <div class="mt-3" v-if="login">
          <div v-if="plans!=undefined" class="d-grid gap-2">
            <router-link v-if="plansLoad == 0" class="btn btn-primary btn-block" to="/buy-plans">See More</router-link>
            <b-button v-else-if="plansLoad == 1" block class="btn btn-primary disabled">Loading...</b-button>
          </div>
        </div> -->
      </div>
    </section>

    <footerBar></footerBar>
<!--    <Footer />-->
    <!-- Footer End -->
<!--    <Switcher />-->
    <!-- Back to top -->
<!--    <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top"-->
<!--       v-scroll-to="'#topnav'">-->
<!--      <arrow-up-icon class="icons"></arrow-up-icon>-->
<!--    </a>-->
    <!-- Back to top -->
  </div>
</template>

<style scoped lang="scss">
.page-next-level .page-next{
  z-index: 9;
}
</style>
